import { useContext, useEffect } from 'react';
import { Table } from 'react-ui-kit-exante';

import { useLazyGetDocsQuery } from '~/api';
import { TDocTypes } from '~/api/docs/docs.types';
import { useCallbackTriggerHandle, useLogHandleTime } from '~/hooks';

import { ApplicationContext } from '../../../../../contexts/ApplicationContext';

import { TABLE_ID } from './UploadedDocumentsTable.constants';
import { getColumns } from './helpers';

export const UploadedDocumentsTable = ({
  type,
  dataDocTypes,
}: {
  type: string;
  dataDocTypes: TDocTypes[];
}) => {
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    `application-entry-${TABLE_ID}-${type}`,
  );
  const { application } = useContext(ApplicationContext);

  const [fetchDocs, { data, isLoading, isSuccess }] = useLazyGetDocsQuery();

  const tableData = data?.results ?? [];

  useEffect(() => {
    if (application?.application?.id) {
      fetchDocs({
        type,
        applicationId: application.application.id,
      });
    }
  }, [application?.application?.id, fetchDocs, type]);

  useEffect(() => {
    if (isLoading) {
      setStartHandleTime();
    }
  }, [isLoading, setStartHandleTime]);

  const columns = getColumns(dataDocTypes, type);

  useCallbackTriggerHandle({
    cb: logHandleTime,
    dataTrigger: data?.results,
    processTrigger: !isLoading && isSuccess,
  });

  if (tableData.length === 0) {
    return null;
  }

  return (
    <Table
      manualSortBy
      isFlexLayout
      disableSortBy
      tableId={type}
      data={tableData}
      columns={columns}
      isHiddenColumnSelect
      showTableInfo={false}
      isLoading={isLoading}
    />
  );
};
