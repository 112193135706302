import { FC, useContext } from 'react';

import { ApplicationContext } from '~/pages/ApplicationEntry/contexts/ApplicationContext';

import { TabStructure } from '../../components/EnrtyScreenStructure/TabStructure/TabStructure';

import { getDefaultClientsValues } from './OnboardingSettingsTab.helpers';

export const OnboardingSettingsTab: FC = () => {
  const { application } = useContext(ApplicationContext);

  const defaultNamesValues = {
    first_name: application?.application?.first_name,
    last_name: application?.application?.last_name,
  };

  const defaultClientsValues = getDefaultClientsValues(
    application?.application?.clients || [],
  );

  return (
    <TabStructure
      entityType="onboarding-settings-tab"
      withMainSave
      additionalFormData={{ ...defaultClientsValues, ...defaultNamesValues }}
    />
  );
};
