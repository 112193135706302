import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { createContext, FC, useContext, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { TChildren } from 'types/TChildren';
import { useSaveApplicationMutation } from '~/api';
import { TTag } from '~/api/applications/applications.types';
import { TDefaultFormValues } from '~/types/form';
import { checkKeyDown } from '~/utils/checkKeyDown';
import { getDirtyValues } from '~/utils/forms/getDirtyValues';

import { ApplicationContext } from '../ApplicationContext';

import {
  TApplicationFormContext,
  TApplicationFormProps,
  TDefaultValue,
} from './ApplicationFormContext.types';
import {
  getDefaultFormValues,
  getFormValidationScheme,
  mapTagData,
} from './helpers';

const initialState: TApplicationFormContext = {
  onSave: () => {},
  formNotEdited: true,
  onAddField: () => {},
  onRemoveField: () => {},
};

export const ApplicationFormContext =
  createContext<TApplicationFormContext>(initialState);

export const ApplicationFormProvider: FC<TApplicationFormProps & TChildren> = ({
  children,
  editableTags,
  allTags,
  additionalFormData,
}) => {
  const { application } = useContext(ApplicationContext);
  const [saveApplication, state] = useSaveApplicationMutation();
  const applicationId = application?.application.id;

  const [newTags, setNewTags] = useState<Record<string, TTag[]>>();
  const [tagsForDeleteIds, setTagsForDeleteIds] = useState<number[]>([]);

  const defaultValues = additionalFormData
    ? {
        ...getDefaultFormValues(editableTags),
        ...additionalFormData,
      }
    : getDefaultFormValues(editableTags);
  const validationScheme = getFormValidationScheme(defaultValues);

  const methods = useForm<TDefaultFormValues>({
    defaultValues,
    resolver: yupResolver(validationScheme),
  });

  const { handleSubmit, getValues, formState } = methods;

  const onAddField = (sectionName: string, tag?: TTag) => {
    if (tag) {
      setNewTags((prevState) => ({
        ...prevState,
        [sectionName]: [tag, ...(prevState?.[sectionName] || [])],
      }));
    }
  };

  const onRemoveField = (tagId: number) => {
    const isNewTag = Object.values(newTags || {})
      .flat()
      .find((t) => t.id === tagId);

    // removing new added tags
    if (isNewTag && newTags) {
      const newTagsCleared = Object.keys(newTags).reduce<
        Record<string, TTag[]>
      >((acc, curr) => {
        return {
          ...acc,
          [curr]: newTags[curr].filter((t) => t.id !== tagId),
        };
      }, {});
      setNewTags(newTagsCleared);
    }

    // removing existed tags
    if (!isNewTag) {
      const tag = editableTags?.find((t) => t.id === tagId);
      if (tag) {
        setTagsForDeleteIds((prevState) => [...prevState, tag.id]);
      }
    }
  };

  const formData = getDirtyValues(formState.dirtyFields, getValues());

  // const changedClientsData =
  //   formData && pickBy(formData, (value, key) => startsWith(key, 'clientId'));

  const onSave = () => {
    const tagsKeys = Object.keys(formData);
    const tagsForSave = allTags
      ?.flatMap(({ tags }) => tags)
      .filter(
        ({ name, id }) =>
          !tagsForDeleteIds.includes(id) && tagsKeys.includes(name),
      );

    const tagData =
      tagsForSave?.map((t) =>
        mapTagData(t, formData[t.name] as TDefaultValue),
      ) || [];

    const deletedTags = tagsForDeleteIds.reduce<{ id: number }[]>(
      (acc, curr) => {
        const tValueId = editableTags?.find((t) => t.id === curr)
          ?.tagvalue_set?.[0]?.id;

        if (tValueId) {
          return [...acc, { id: tValueId }];
        }
        return acc;
      },
      [],
    );

    if (applicationId) {
      saveApplication({
        applicationId,
        params: {
          tags: tagData,
          ...(deletedTags.length ? { tags_to_delete: deletedTags } : {}),
        },
      });
    }
  };

  const value = useMemo(
    (): TApplicationFormContext => ({
      onSave,
      formNotEdited: !tagsForDeleteIds.length && isEmpty(formData),
      allTags,
      onAddField,
      onRemoveField,
      newTags,
      tagsForDeleteIds,
      editableTags,
      isLoading: state.isLoading,
    }),
    [
      onSave,
      formData,
      onAddField,
      newTags,
      state.isLoading,
      tagsForDeleteIds.length,
    ],
  );

  return (
    <ApplicationFormContext.Provider value={value}>
      <FormProvider {...methods}>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <form
          onSubmit={handleSubmit(onSave)}
          onKeyDown={(e) => checkKeyDown(e)}
        >
          {children}
        </form>
      </FormProvider>
    </ApplicationFormContext.Provider>
  );
};
