import { TContentType } from '~/api/globalContext/globalContext.types';
import { getTableId } from '~/utils/table';

export enum ECommentType {
  Text = 8,
}

export const mapCommentType = {
  deposits: ECommentType.Text,
  withdrawals: ECommentType.Text,
};

export const mapContentType: Record<string, keyof TContentType> = {
  deposits: 'depositinfo',
  withdrawals: 'withdrawal',
};

export const PAGE_SIZE = 10;
export const PAGE_SIZES = [10, 20, 50, 100, 1000, 5000];
export const TABLE_ID = getTableId('comments');
