import { FC, useContext } from 'react';

import { StaticSectionKey } from '../../../ApplicationEntry.constants';
import { ApplicationFormContext } from '../../../contexts/FormContext';
import { SectionBody } from '../SectionBody';

import { mapSectionKeyToStaticComponent } from './Section.constants';
import { StyledSection, StyledTitle } from './Section.styled';
import { TSectionProps } from './Section.types';

export const Section: FC<TSectionProps> = ({ section, tags }) => {
  const sectionIsStatic = section.resourcetype === StaticSectionKey;
  const sectionName = section.name;
  const { newTags } = useContext(ApplicationFormContext);

  const tagKey = section?.children?.[0].name;

  const newTagsForSection =
    tagKey && newTags && tagKey in newTags && sectionName
      ? newTags[tagKey]
      : [];

  const allTags = [...newTagsForSection, ...(tags ?? [])];

  return (
    <>
      <StyledSection className="Section">
        {section.title !== 'Uploaded Docs' && (
          <StyledTitle className="StyledTitle">{section.title}</StyledTitle>
        )}
        {sectionIsStatic &&
          sectionName &&
          sectionName !== 'client-and-legal-entity-section' &&
          mapSectionKeyToStaticComponent[sectionName]}
        {allTags && <SectionBody tags={allTags} />}
      </StyledSection>
      {sectionName === 'client-and-legal-entity-section' &&
        mapSectionKeyToStaticComponent[sectionName]}
    </>
  );
};
