import { useContext, useEffect, useMemo } from 'react';
import { Table } from 'react-ui-kit-exante';

import { useGetApplicationLogsQuery } from '~/api';
import { ValueRow } from '~/components/ValueRow';
import { useCallbackTriggerHandle, useLogHandleTime } from '~/hooks';

import { ApplicationContext } from '../../../../contexts/ApplicationContext';

import { COLUMNS, TABLE_ID } from './OnboardingLog.constants';
import { StyledOnboardingLogList } from './OnboardingLog.styled';

export const OnboardingLog = () => {
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    `application-entry-${TABLE_ID}`,
  );
  const { application } = useContext(ApplicationContext);

  const applicationId = application?.application?.id;

  const {
    data: logs,
    isLoading,
    isSuccess,
  } = useGetApplicationLogsQuery({
    id: applicationId,
  });

  useEffect(() => {
    if (isLoading) {
      setStartHandleTime();
    }
  }, [isLoading, setStartHandleTime]);

  const logsTableData = useMemo(
    () =>
      logs?.map((log) => ({
        status: `${log[0]} > ${log[1]}`,
        days: log[2],
      })),
    [logs],
  );

  useCallbackTriggerHandle({
    cb: logHandleTime,
    dataTrigger: logsTableData,
    processTrigger: !isLoading && isSuccess,
  });

  return (
    <>
      <StyledOnboardingLogList className="OnboardingLogList">
        <ValueRow label="Application ID" value={applicationId} />
        <ValueRow
          label="Approved by"
          value={application?.approved_by_full_name}
        />
        <ValueRow label="Approved date" value={application?.approved_date} />
      </StyledOnboardingLogList>
      <Table
        isFlexLayout
        tableId={TABLE_ID}
        columns={COLUMNS}
        data={logsTableData ?? []}
      />
    </>
  );
};
