import { styled } from 'react-ui-kit-exante';

export const StyledClientFormClientHeader = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const StyledClientFormActions = styled('div')`
  display: flex;
  gap: 16px;
`;
