import { styled } from 'react-ui-kit-exante';

export const StyledSectionBody = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledFormField = styled('div')`
  min-width: 270px;
  max-width: 270px;

  display: flex;
  gap: 12px;
  justify-content: right;
  align-items: center;

  // hack for checkboxes
  .MuiFormControlLabel-root {
    display: flex;
    justify-content: right;
  }
`;
