import { useContext } from 'react';

import { useGetGlobalContextQuery } from '~/api';
import { EntrySection } from '~/components/EntryScreen';
import { ValueRow } from '~/components/ValueRow';
import { ApplicationContext } from '~/pages/ApplicationEntry/contexts/ApplicationContext';
import { ClientFormHeader } from '~/pages/ApplicationEntry/tabs/OnboardingSettingsTab/components/ClientForm/ClientFormHeader';
import { getFormField } from '~/utils/getFormField';

import { getClientFormFields } from './ClientForm.helpers';
import { StyledClientFormField } from './ClientForm.styled';

export const ClientForm = () => {
  const { application } = useContext(ApplicationContext);
  const clients = application?.application?.clients;
  const { data: globalContext } = useGetGlobalContextQuery();

  const allOptions = globalContext?.tags_choices;

  if (clients) {
    return (
      <div>
        {clients?.map((client) => {
          const {
            client_id: clientId,
            id,
            closed_only: closedOnly,
            closed_account: closedAccount,
            blocked_account: blockedAccount,
          } = client;

          return (
            <EntrySection
              gap="8px"
              key={clientId}
              title={
                <ClientFormHeader
                  id={id}
                  clientId={clientId}
                  closedOnly={closedOnly}
                  closedAccount={closedAccount}
                  blockedAccount={blockedAccount}
                />
              }
            >
              {getClientFormFields(allOptions).map(
                ({ name, title, type, options }) => {
                  return (
                    <ValueRow
                      key={name}
                      label={title}
                      valueNode={
                        <StyledClientFormField className="ClientFormField">
                          {getFormField({
                            type,
                            name: `clientId:${id}_${name}`,
                            options,
                          })}
                        </StyledClientFormField>
                      }
                    />
                  );
                },
              )}
            </EntrySection>
          );
        })}
      </div>
    );
  }

  return null;
};
