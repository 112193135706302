import { FC, useContext, useEffect, useMemo } from 'react';
import { IconButton } from 'react-ui-kit-exante';

import { useLazyGetTagsChoicesQuery } from '~/api';
import { ValueRow } from '~/components/ValueRow';
import { FieldTypes, getFieldType } from '~/constants/common';
import { getFormField } from '~/utils/getFormField';

import { getTagValue } from '../../../ApplicationEntry.helpers';
import { ApplicationContext } from '../../../contexts/ApplicationContext';
import { ApplicationFormContext } from '../../../contexts/FormContext';
import { StyledFormField } from '../SectionBody/SectionBody.styled';

import { TTagProps } from './Tag.types';

export const Tag: FC<TTagProps> = ({ tag }) => {
  const [getTagsChoices, tagChoicesState] = useLazyGetTagsChoicesQuery();
  const { application } = useContext(ApplicationContext);
  const { onRemoveField, newTags, tagsForDeleteIds } = useContext(
    ApplicationFormContext,
  );
  const {
    readonly,
    tagvalue_set: tagValueSet,
    id,
    title,
    name,
    choices,
    is_multiple: isMultiple,
    editable_choices: editableChoices,
  } = tag;
  const tValue = tagValueSet?.[0];

  const fieldType = getFieldType(tag);

  const normalizedChoices = useMemo(() => {
    if (choices?.length) {
      return choices.split(',').map((i) => ({
        value: i,
        label: i,
      }));
    }
    return null;
  }, [choices]);

  useEffect(() => {
    const appId = application?.application?.id;
    if (appId) {
      if (
        !normalizedChoices &&
        [FieldTypes.Select, FieldTypes.Autocomplete].includes(fieldType)
      ) {
        getTagsChoices({
          contentType: 22,
          objectId: appId,
          tagId: id,
        });
      }
    }
  }, [application?.application.id, normalizedChoices, fieldType, id]);

  const onRemoveTag = () => {
    onRemoveField(id);
  };

  const isTagRemovable = useMemo(() => {
    if (tValue?.removable) {
      return true;
    }

    return (
      newTags &&
      Object.values(newTags)
        .flat()
        .find((t) => t.id === id)
    );
  }, [tValue, newTags]);

  if (tagsForDeleteIds?.includes(id)) {
    return null;
  }

  if (readonly || tValue?.readonly || tValue?.is_can_edit === false) {
    return <ValueRow key={id} label={title || name} value={getTagValue(tag)} />;
  }

  return (
    <div key={id}>
      <ValueRow
        key={id}
        label={title || name}
        valueNode={
          <StyledFormField className="FormField">
            {getFormField({
              type: fieldType,
              name,
              options: normalizedChoices ?? tagChoicesState.data,
              disabled: tagChoicesState.isLoading,
              isMultiple,
              freeSolo: editableChoices,
            })}
            {isTagRemovable && (
              <IconButton
                iconName="DeleteIcon"
                iconSize={18}
                onClick={onRemoveTag}
              />
            )}
          </StyledFormField>
        }
      />
    </div>
  );
};
