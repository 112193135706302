import { FC } from 'react';

import { Tag } from '../Tag';

import { StyledSectionBody } from './SectionBody.styled';
import { TSectionBodyProps } from './SectionBody.types';

export const SectionBody: FC<TSectionBodyProps> = ({ tags = [] }) => {
  return (
    <StyledSectionBody className="SectionBody">
      {tags?.length > 0 &&
        tags.map((tag) => {
          return <Tag key={tag.id} tag={tag} />;
        })}
    </StyledSectionBody>
  );
};
