import { TConfirms } from '~/modules/confirms/components/Confirms/Confirms.types';

export type TWithdrawalsData = {
  status: EWithdrawalsStatuses;
  id: number;
  responsible_title: string;
  username: string;
  uploads: TUpload[];
  account_owner: string;
  banks_to_transfer: TBanksToTransfer[];
  currency: string;
  application: { type: number };
  accounts: string[];
  hidden_edit_fields: string[];
  opposite_whitelabel: string;
  stages: TConfirms;
  ip_country: string;
  ip: string;
  is_crypto_withdrawn_waiting_result: boolean;
  is_crypto_withdrawn: boolean;
  transfer_using_personal_iban: boolean;
  account_owner_crm_link: string;
  crm_link: string;
  backoffice_confirm: boolean;
  is_withdrawn: boolean;
  all_funds_transfer_allowed: boolean;
  actions: Record<string, boolean>;
  is_locked: boolean;
  beneficiary_type: string | null;
  sepa_swift?: string;
  type?: string;
  withdrawal_securities?: TSecurity[];
  country_of_citizenship?: string;
  country_of_residence?: string;
  crypto_transaction_id?: string;
  transaction_url?: string;
  asset?: string;
  network?: string;
  commission_amount?: string;
  commission_currency?: string;
};

export type TBanksToTransfer = {
  application_type: number;
  bank_name: string;
  currency: string;
  number: string;
};

export type TUpload = {
  created: string;
  id: number;
  is_removed: number;
  modified: string;
  name: string;
  withdrawal: number;
};

export type TUploadParam = { id: number; file: File };

export type TWithdrawalsExportData = {
  message: string;
};

export type TWithdrawalsExportPayload = {
  columns: string[];
  withdrawals_ids: string[];
};

export enum EWithdrawalsStatuses {
  Active = 'active',
  Executed = 'executed',
  Rejected = 'rejected',
  OnHold = 'on-hold',
  Old = 'old',
}

export type TReasons = {
  id: number;
  reason_text: string;
};

export type TDeleteFileParams = { withdrawalId: number; fileId: string };

export type TPostWithdrawalConfirmParams = { id: string; confirm: string };

export type TClientDocuments = {
  id: string;
  created: string;
  file_url: string;
  file_basename: string;
};

export type TSecurity = {
  id: number;

  commission: string | null;
  currency: string;
  custodian: null;
  exchange: string;
  isin_code: string;
  price: string;
  quantity: string;
  security_name: string;
  security_type: string;
  settlement_date: string | null;
  trade_date: string | null;
};
