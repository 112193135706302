import { pick } from 'lodash';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  Table,
  calculateCountOfPages,
  useTableData,
  IconButton,
  Notification,
  OnSaveEditableRow,
} from 'react-ui-kit-exante';

import {
  useLazyGetTradesQuery,
  useGetAccountTypesQuery,
  useUpdateTradeMutation,
  useRollbackTradeMutation,
} from '~/api';
import {
  DEFAULT_TRADES_RESPONSE,
  REQUESTED_FIELDS,
  TRADES_PARAMS_MAPPER,
  TTrade,
} from '~/api/nodeBackApi';
import { ActionWithConfirmation } from '~/components/ConfirmationComponents/ActionWithConfirmation';
import { DownloadButton } from '~/components/DownloadButton';
import { RefreshButton } from '~/components/RefreshButton';
import { useCallbackTriggerHandle, useLogHandleTime } from '~/hooks';
import { ApplicationContext } from '~/pages/ApplicationEntry/contexts/ApplicationContext';
import { TParams } from '~/router/router.types';
import { transformVariantsToSelectOptions } from '~/utils/forms/transformVariantsToSelectOptions';
import { paramsTransformer } from '~/utils/params';
import { getDefaultPagination } from '~/utils/table';
import { createLinkToDownloadCSV } from '~/utils/table/createLinkToDownloadCSV';

import { useAccountsByUser } from '../AuditLogsTable/useAccountsByUser';

import {
  DEFAULT_SORTING_TS,
  DISPLAYED_COLUMN_KEYS,
  TABLE_ID,
} from './TradeTable.constants';
import {
  getAdditionalFilters,
  useColumns,
  getDefaultFilters,
} from './TradeTable.helpers';
import { TTradeState } from './TradeTable.types';

export const TradeTable: FC = () => {
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    `application-entry-${TABLE_ID}`,
  );
  const { id } = useParams<TParams>();
  const { activeLegalEntity } = useContext(ApplicationContext);

  const [rollbackData, setRollbackData] = useState<null | {
    orderId: string;
    orderPosition: number;
  }>(null);

  const { accountsList, accountListLoading } = useAccountsByUser(
    String(id),
    true,
  );

  const [
    fetchTrades,
    { isLoading: isGetTradesLoading, isSuccess: isGetTradesSuccess },
  ] = useLazyGetTradesQuery();
  const { data: accountTypes } = useGetAccountTypesQuery();
  const [updateTrade, { isLoading: isUpdateTradeLoading }] =
    useUpdateTradeMutation();
  const [rollbackTrade, stateRollback] = useRollbackTradeMutation();

  const isLoadingRollback = stateRollback.isLoading;

  const accountTypesOptions = transformVariantsToSelectOptions(
    accountTypes?.values,
  );

  useEffect(() => {
    if (isGetTradesLoading) {
      setStartHandleTime();
    }
  }, [isGetTradesLoading, setStartHandleTime]);

  const getTrades = useCallback(
    async ({ params }: { params: Record<string, unknown> }) => {
      if (!(accountsList.length > 0)) {
        return DEFAULT_TRADES_RESPONSE;
      }

      delete params.page;

      const { data } = await fetchTrades({
        ...params,
        accountId: accountsList.join(),
      });

      return data;
    },

    [accountListLoading, setStartHandleTime, fetchTrades, accountsList],
  );

  const tableDataArgs = useMemo(
    () => ({
      data: { onFetch: getTrades },
      filters: { getDefaultFilters, required: ['fromTo'] },
      tableId: TABLE_ID,
      saveViewParamsAfterLeave: true,
      pagination: {
        getDefaultPagination,
      },
      sorting: { getDefaultSorting: () => DEFAULT_SORTING_TS },
    }),
    [getTrades],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    setSorting,
    filters,
    fetchData: refetch,
  } = useTableData<TTradeState | undefined>(tableDataArgs);

  const total = data?.pagination?.total || 0;

  const setFilterByLEFromHeader = () => {
    if (
      typeof activeLegalEntity === 'string' &&
      !activeLegalEntity &&
      filters?.legalEntity
    ) {
      setFilter('legalEntity', []);
    } else if (activeLegalEntity) {
      setFilter('legalEntity', [activeLegalEntity]);
    }
  };

  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const columns = useColumns({
    onFilter: setFilter,
    onRemove: removeFilter,
  });

  const handleRollbackTrade = useCallback(async () => {
    if (!rollbackData) {
      return;
    }

    const res = await rollbackTrade({
      orderId: rollbackData.orderId,
      orderPosition: rollbackData.orderPosition,
    });

    if (!('error' in res)) {
      refetch();

      Notification.success({
        title: 'Trade was rolled back',
      });
    }

    setRollbackData(null);
  }, [rollbackData, rollbackTrade, refetch]);

  const onSaveRowHandler: OnSaveEditableRow<TTrade> = useCallback(
    async (_, newRow) => {
      const result = await updateTrade(
        pick(newRow, [
          'orderId',
          'orderPos',
          'clientComment',
          'internalComment',
        ]),
      );

      if (!('error' in result)) {
        refetch();

        Notification.success({
          title: 'Trade has been updated',
        });
      } else {
        Notification.error({
          title: 'Error updating trade',
        });
      }
    },
    [refetch, updateTrade],
  );

  const additionalFilters = useMemo(
    () =>
      getAdditionalFilters({
        onFilter: setFilter,
        onRemove: removeFilter,
        defaultFilters: getDefaultFilters(),
        accountTypes: accountTypesOptions,
      }),
    [accountTypesOptions, removeFilter, setFilter],
  );

  const filterProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      additionalFilters,
      filters,
      manualFilters: true,
    }),
    [filters, resetFilters, additionalFilters],
  );

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount,
    }),
    [limit, page, pageCount, setLimit, setPage, total],
  );

  const rowActions = useMemo(
    () => ({
      show: true,
      onSave: onSaveRowHandler,
      order: 1,
      additionalActions: (rowData?: TTrade) => {
        const getOpenConfirm = () => rowData?.orderId === rollbackData?.orderId;
        const isOpenConfirm = getOpenConfirm();

        return [
          {
            label: (
              <ActionWithConfirmation
                placement="bottom"
                cancelButtonNameKey="Cancel"
                confirmButtonNameKey="Rollback"
                onConfirm={handleRollbackTrade}
                title={`Rollback trade id: ${rollbackData?.orderId}?`}
                disabled={isLoading}
                closeHandler={() => setRollbackData(null)}
                externalOpened={isOpenConfirm}
              >
                <IconButton
                  iconSize={16}
                  title="Rollback"
                  iconName="RollbackIcon"
                  iconColor="secondary"
                />
              </ActionWithConfirmation>
            ),
            title: 'Rollback',
            order: 0,
            onClick: ({ orderId, orderPos }: TTrade) =>
              setRollbackData({
                orderId,
                orderPosition: orderPos,
              }),
          },
        ];
      },
    }),
    [
      handleRollbackTrade,
      isLoading,
      onSaveRowHandler,
      rollbackData?.orderId,
      refetch,
    ],
  );

  const resultParams = paramsTransformer({
    params: {
      ...filters,
      limit: Infinity,
      fields: REQUESTED_FIELDS,
      accountId: accountsList.join(),
    },
    mapper: TRADES_PARAMS_MAPPER,
  });

  const additionalActions = [
    {
      key: 'refresh',
      component: (
        <RefreshButton
          onRefresh={refetch}
          disabled={isLoading}
          iconColor="secondary"
          title="Refresh table data"
        />
      ),
    },
    {
      key: 'download',
      component: (
        <DownloadButton
          title="Export CSV"
          fileName="trades.csv"
          link={createLinkToDownloadCSV('/api/trades/csv', resultParams)}
        />
      ),
    },
  ];

  useCallbackTriggerHandle({
    cb: logHandleTime,
    dataTrigger: data?.trades,
    processTrigger: !isGetTradesLoading && isGetTradesSuccess,
  });

  useEffect(() => {
    setRollbackData(null);
  }, [isLoadingRollback]);

  useEffect(() => {
    setFilterByLEFromHeader();
  }, [activeLegalEntity]);

  return (
    <Table
      className="TradeTable"
      columns={columns}
      displayedColumnKeys={DISPLAYED_COLUMN_KEYS}
      isLoading={isLoading || isUpdateTradeLoading || isLoadingRollback}
      filtersExpanded
      isFlexLayout
      manualSortBy
      hasFilters
      filteringProps={filterProps}
      data={data?.trades ?? []}
      tableId={TABLE_ID}
      hasPagination
      showTableInfo
      saveColumnOrder
      defaultSortBy={DEFAULT_SORTING_TS}
      serverPaginationProps={serverPaginationProps}
      saveViewParamsAfterLeave
      onSort={setSorting}
      rowActions={rowActions}
      additionalActions={additionalActions}
    />
  );
};
