export const getNameFormFields = () => {
  return [
    {
      name: 'first_name',
      title: 'First name',
      editable: true,
    },
    {
      name: 'last_name',
      title: 'Last name',
      editable: true,
    },
  ];
};
