import { isArray, isBoolean } from 'lodash';
import { object, string } from 'yup';

import { TTag, TTagForSave } from '~/api/applications/applications.types';
import { formatDate } from '~/utils/dates/formatDate';

import { TDefaultValue, TValues } from './ApplicationFormContext.types';

export const getDefaultFormValues = (tags?: TTag[]) => {
  if (!tags) {
    return {};
  }

  return tags.reduce((acc, curr): TValues => {
    let defaultValue: TDefaultValue;

    const tValueSet = curr?.tagvalue_set;

    if (curr.type === 'flag') {
      defaultValue = tValueSet?.[0]?.bool_value || curr?.default_bool_value;
    } else if (curr.type === 'date') {
      defaultValue = tValueSet?.[0]?.date_value
        ? new Date(tValueSet[0].date_value)
        : '';
    } else {
      const value = tValueSet?.[0]?.t_value;
      if (!value) {
        defaultValue = null;
      }

      if (typeof value === 'string') {
        defaultValue = value;
      } else if (isArray(value)) {
        // multiple autocomplete need array value
        if (curr.is_multiple) {
          defaultValue =
            value.length === 1 ? [value[0]?.id] : value.map((i) => i.id);
        } else {
          defaultValue =
            value.length === 1 ? value[0]?.id : value.map((i) => i.id);
        }
      } else {
        defaultValue = value?.id;
      }
    }

    const key = curr.name;

    return {
      [key]: defaultValue,
      ...acc,
    };
  }, {});
};

// here we can set any validation scheme for all fields
// example from old CRM
// https://gitlab.exan.tech/Exante-websites/exante-crm-frontend/-/blob/release/1.41/src/modules/tags/components/tags-group/tag/tags/reasons-for-leaving-comment.js?ref_type=heads
export const getFormValidationScheme = (defaultValues: TValues) => {
  const fields: Record<string, any> = {};
  const keys = Object.keys(defaultValues);
  if (keys.includes('reasons for leaving')) {
    fields['reasons for leaving comment'] = string().test(
      'reasons for leaving comment',
      'Please fill Reasons for leaving comment',
      (value, context) => {
        return context.parent['reasons for leaving']?.length ? !!value : true;
      },
    );
  }
  return object({ ...fields });
};

export const mapTagData = (tag: TTag, value: TDefaultValue): TTagForSave => {
  const id = tag?.tagvalue_set?.[0]?.id || null;
  const tagId = tag.id;
  const data = {
    tag_id: tagId,
    id,
  };

  if (tag.type === 'flag') {
    return {
      ...data,
      bool_value: isBoolean(value) ? value : undefined,
    };
  }

  if (tag.type === 'date') {
    return {
      ...data,
      date_value: value ? formatDate(String(value)) : null,
    };
  }

  if (tag.type === 'manager') {
    return {
      ...data,
      manager_id: value ? Number(value) : null,
    };
  }

  return {
    tag_id: tag.id,
    id,
    text_value: value ? String(value) : null,
  };
};
