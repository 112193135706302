import { ReactNode } from 'react';

import { ClientForm } from '~/pages/ApplicationEntry/tabs/OnboardingSettingsTab/components/ClientForm';
import { NameForm } from '~/pages/ApplicationEntry/tabs/OnboardingSettingsTab/components/NameForm';
import { OnboardingLog } from '~/pages/ApplicationEntry/tabs/OnboardingSettingsTab/components/OnboardingLog';
import { UploadedDocuments } from '~/pages/ApplicationEntry/tabs/OnboardingSettingsTab/components/UploadedDocuments';
import { ValidationAndCategorization } from '~/pages/ApplicationEntry/tabs/OnboardingSettingsTab/components/ValidationAndCategorization';

import { AddNewTag } from './StaticSectionComponents/AddNewTag';

export const mapSectionKeyToStaticComponent: Record<string, ReactNode> = {
  'add-new-tag-section': <AddNewTag />,
  'name-section': <NameForm />,
  'onboarding-log-section': <OnboardingLog />,
  'client-and-legal-entity-section': <ClientForm />,
  'uploaded-docs-section': <UploadedDocuments />,
  'onboarding-questions-section': <ValidationAndCategorization />,
};
