import { ValueRow } from '~/components/ValueRow';
import { getFormField } from '~/utils/getFormField';

import { getNameFormFields } from './NameForm.helpers';
import {
  StyledOnboardingLogList,
  StyledClientFormField,
} from './NewForm.styled';

export const NameForm = () => {
  return (
    <StyledOnboardingLogList className="OnboardingLogList">
      {getNameFormFields().map(({ name, title }) => {
        return (
          <ValueRow
            key={name}
            label={title}
            valueNode={
              <StyledClientFormField className="ClientFormField">
                {getFormField({
                  name,
                })}
              </StyledClientFormField>
            }
          />
        );
      })}
    </StyledOnboardingLogList>
  );
};
