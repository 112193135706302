import { isArray } from 'lodash';
import { SyntheticEvent, useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Autocomplete, ISelectOption } from 'react-ui-kit-exante';

import { TFormMultiSelectContainerProps } from './FormMultiSelectContainer.types';

export const FormMultiSelectContainer = ({
  fullWidth = true,
  label = '',
  name,
  options,
  disabled = false,
  isMultiple = false,
  size = 'medium',
  disableClearable,
  freeSolo = false,
}: TFormMultiSelectContainerProps) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const multiSelectChangeHandler = useCallback(
    (
      _: SyntheticEvent<Element, Event>,
      newValues: Array<string | ISelectOption> | ISelectOption,
    ) => {
      if (isArray(newValues)) {
        onChange(
          newValues?.map((newValue) => {
            if (typeof newValue === 'string') {
              return newValue;
            }
            return newValue.value;
          }),
        );
      } else {
        onChange(newValues.value);
      }
    },
    [onChange],
  );

  return (
    <Autocomplete
      fullWidth={fullWidth}
      isMultiple={isMultiple}
      isOptionEqualToValue={(option, selectedValue) =>
        option.value === selectedValue
      }
      disableClearable={disableClearable}
      onChange={multiSelectChangeHandler}
      options={options}
      placeholder={label}
      value={value}
      disabled={disabled}
      size={size}
      controlProps={{
        error: Boolean(error),
        message: error?.message,
      }}
      freeSolo={freeSolo}
    />
  );
};
