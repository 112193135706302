import { apiRequest } from '~/utils/apiRequest';

import { TDictionary } from './dictionaries.types';

export const fetchFilterDictionary = async (
  url: string,
  params?: Record<string, any>,
) => {
  try {
    const { data } = await apiRequest<TDictionary[]>({
      url,
      params,
    });

    return data.map(({ id, name, text }) => ({
      value: String(id),
      label: name || text || String(id),
    }));
  } catch (e) {
    return [];
  }
};
