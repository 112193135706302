import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  IOnFetchArguments,
  useTableData,
  Table,
  Checkbox,
  Input,
} from 'react-ui-kit-exante';

import {
  useCreateCommentMutation,
  useGetPersonalSettingsQuery,
  useUpdatePersonalSettingsMutation,
} from '~/api';
import { useLazyGetCommentsQuery } from '~/api/comments/commentsApi';
import { useGetGlobalContextQuery } from '~/api/globalContext';
import { useCallbackTriggerHandle, useLogHandleTime } from '~/hooks';
import {
  calculateCountOfPages,
  getDefaultPagination,
  getPaginationParams,
} from '~/utils/table';

import {
  mapCommentType,
  mapContentType,
  PAGE_SIZE,
  PAGE_SIZES,
  TABLE_ID,
} from './Comments.constants';
import { StyledButton, StyledRowContainer } from './Comments.styled';
import { TCommentsProps } from './Comments.types';
import { getColumns } from './columns';

export const Comments: FC<TCommentsProps> = ({
  entityId,
  entityType,
  needRefetch,
}) => {
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    `withdrawal-entry-comments-${TABLE_ID}-${entityType}`,
  );
  const [comment, setComment] = useState('');

  const [updatePersonalSettings, personalSettingsState] =
    useUpdatePersonalSettingsMutation();
  const [onCommentCreate, commentCreateState] = useCreateCommentMutation();
  const { data: personalSettings } = useGetPersonalSettingsQuery();
  const { data: globalContext } = useGetGlobalContextQuery();
  const [
    fetchComments,
    { isLoading: isGetCommentsLoading, isSuccess: isGetCommentsSuccess },
  ] = useLazyGetCommentsQuery();

  useEffect(() => {
    if (isGetCommentsLoading) {
      setStartHandleTime();
    }
  }, [isGetCommentsLoading, setStartHandleTime]);

  const isHideSystem = Boolean(
    Number(personalSettings?.personal_settings.hyde_system_comments),
  );

  const contentType =
    globalContext?.content_type?.[mapContentType[entityType]] ?? 0;

  const getComments = useCallback(
    async (params: IOnFetchArguments) => {
      const paginationParams = getPaginationParams(params, true);
      const response = await fetchComments({
        entityId,
        perPage: paginationParams.iDisplayLength,
        page: paginationParams.iDisplayPage
          ? paginationParams.iDisplayPage + 1
          : 1,
        contentType,
        isSystem: !isHideSystem,
      });
      return response.data;
    },
    [isHideSystem],
  );

  const tableDataArgs = useMemo(
    () => ({
      tableId: TABLE_ID,
      data: { onFetch: getComments },
      pagination: {
        getDefaultPagination,
      },
    }),
    [getComments],
  );

  const { data, limit, setLimit, setPage, page, skip, isLoading, fetchData } =
    useTableData(tableDataArgs);

  const total = data?.[0].total_entries ?? 0;

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      skip,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount: calculateCountOfPages(total, limit),
    }),
    [skip, limit, page, setLimit, setPage, total],
  );

  const onIsSystemCheckboxChange = (
    _: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setPage(0);

    updatePersonalSettings({
      id: 0,
      personal_settings: {
        hyde_system_comments: String(Number(checked)),
      },
    });
  };

  const columns = useMemo(() => {
    return getColumns();
  }, []);

  const handleChangeComment = (event: ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const onAddComment = async () => {
    const response = await onCommentCreate({
      content: comment,
      answered: false,
      content_type: contentType,
      hidden: false,
      is_system: false,
      kind: mapCommentType[entityType],
      object_id: entityId,
      removed: false,
    });

    if (!('error' in response)) {
      setComment('');
      fetchData();
    }
  };

  const isAddCommentDisabled =
    !comment.trim().length || commentCreateState.isLoading || isLoading;

  useEffect(() => {
    if (needRefetch) {
      fetchData();
    }
  }, [needRefetch]);

  useCallbackTriggerHandle({
    cb: logHandleTime,
    dataTrigger: data?.[1],
    processTrigger: !isGetCommentsLoading && isGetCommentsSuccess,
  });

  return (
    <div>
      <StyledRowContainer className="AddCommentContainer">
        <Input
          placeholder="Your comment"
          value={comment}
          onChange={handleChangeComment}
          data-test-id="clientcard__textarea--comment"
          fullWidth
        />
        <StyledButton
          onClick={onAddComment}
          disabled={isAddCommentDisabled}
          color="primary"
          fullWidth
          data-test-id="clientcard__button--add-comment"
        >
          Add comment
        </StyledButton>
      </StyledRowContainer>
      <StyledRowContainer className="RowContainer">
        <Checkbox
          label="Hide system comments"
          checked={isHideSystem}
          onChange={onIsSystemCheckboxChange}
          disabled={personalSettingsState.isLoading}
        />
      </StyledRowContainer>
      <Table
        tableId={TABLE_ID}
        data={data?.[1] || []}
        columns={columns}
        manualSortBy
        isLoading={isLoading && !needRefetch}
        serverPaginationProps={serverPaginationProps}
        skeletonsCount={PAGE_SIZE}
        pageSizes={PAGE_SIZES}
        pageSize={PAGE_SIZE}
        isFlexLayout
        disableSortBy
        isHiddenColumnSelect
        showTableInfo={false}
        noHeader
        hasPagination
      />
    </div>
  );
};
